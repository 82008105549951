import React from 'react'

function Service() {
  return (
    <>
      <div className='row show-case-1 mt-8 p-10 flex items-center'>
        <h1 className="text-3xl p-10  text-orange-700 font-serif text-center" data-aos='flip-left'> Web Application </h1>
        <div className='col-md-6 shadow-lg ' data-aos='fade-up-right'>
            <img className='img' src='./photo/image-3.jpg' />
        </div>
        <div className='col-md-6  shadow-lg items-center ' data-aos='fade-up-left'>
            <h3 className='text-lg p-10 text-orange-700 font-serif text-center'>Software develop </h3>
            <p className='text-sm text-left text-gray-300 '> In the fast-paced digital era we live in, software developers are the unsung heroes who shape our technological landscape. These skilled individuals possess the power to turn mere concepts into robust, functional software applications. In this article, we will explore the captivating world of a software developer, shedding light on their role, skills, and the impact they have on our daily lives.

                The Role of a Software Developer:
                A software developer is an innovative problem solver who bridges the gap between a vision and its practical realization. They are responsible for designing, coding, testing, and maintaining software applications. Their work extends beyond writing code; they collaborate with cross-functional teams, gather requirements, and ensure the final product meets the needs of users.</p>
        </div>
    </div>

    <div className='row  show-case-2 mt-8 p-10 flex items-center'>
        <h1 className="text-3xl p-10 text-orange-700 font-serif text-center" data-aos='flip-left'> Software problem issue </h1>
        <div className='col-md-6 shadow-lg items-center ' data-aos='fade-up-left'>
            <h3 className='text-lg p-10 text-orange-700 font-serif text-center'>Software develop </h3>
            <p className='text-sm text-left text-gray-300 '> In the fast-paced digital era we live in, software developers are the unsung heroes who shape our technological landscape. These skilled individuals possess the power to turn mere concepts into robust, functional software applications. In this article, we will explore the captivating world of a software developer, shedding light on their role, skills, and the impact they have on our daily lives.

                The Role of a Software Developer:
                A software developer is an innovative problem solver who bridges the gap between a vision and its practical realization. They are responsible for designing, coding, testing, and maintaining software applications. Their work extends beyond writing code; they collaborate with cross-functional teams, gather requirements, and ensure the final product meets the needs of users.</p>
        </div>
        <div className='col-md-6 shadow-lg ' data-aos='fade-up-right'>
            <img className='img' src='./photo/image-4.jpg' />
        </div>
    </div>
    
    <div className='row  show-case-3 mt-8 p-10 flex items-center'>
        <h1 className="text-3xl p-10 text-orange-700 font-serif text-center" data-aos='flip-left'> Mobile develop </h1>
        <div className='col-md-6 shadow-lg ' data-aos='fade-up-right'>
            <img className='img' src='./photo/image-1.jpg' />
        </div>
        <div className='col-md-6 shadow-lg items-center ' data-aos='fade-up-left'>
            <h3 className='text-lg p-10 text-orange-700 font-serif text-center'>Software develop </h3>
            <p className='text-sm text-left text-gray-300 '> In the fast-paced digital era we live in, software developers are the unsung heroes who shape our technological landscape. These skilled individuals possess the power to turn mere concepts into robust, functional software applications. In this article, we will explore the captivating world of a software developer, shedding light on their role, skills, and the impact they have on our daily lives.

                The Role of a Software Developer:
                A software developer is an innovative problem solver who bridges the gap between a vision and its practical realization. They are responsible for designing, coding, testing, and maintaining software applications. Their work extends beyond writing code; they collaborate with cross-functional teams, gather requirements, and ensure the final product meets the needs of users.</p>
        </div>
    </div>
    </>
  )
}

export default Service