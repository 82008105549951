import React, { useEffect, useReducer, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faGithub, faInstagram, faTwitter,faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import axios from 'axios'
import { Alert } from '@mui/material'


const initialState = {
    name: '',
    email: '',
    subject: '',
    message: ''
}

const reducer = (state, action) => {
    switch (action.type) {
        case "update":
            return{
                ...state,
                [action.name]: action.value,
            };
            case "delete":
                return{
                    
                    state
                }
        default:
            return state
    }
}

function Home() {

    const[data, disptch] = useReducer( reducer , initialState);
    const [notify , setNotify] = useState();
    const [info , setInfo] = useState();


    const handleChanage = (e) =>{
       disptch({
        type: "update",
                name: e.target.name,
                value: e.target.value
       })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
       

       await axios.post('https://mirajapi.akronlineshop.com/api/mail', {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message
       }).then(res => {
        setNotify(res.data.message)
        disptch({
            type: 'delete',
        })
    
       }).catch(err => {
        console.log(err)
       })

    }

    useEffect(() =>{
        fetch("https://mirajapi.akronlineshop.com/api/info").then(res =>res.json()).then((result) =>{
            setInfo('photo/'+result.path);
        })
    },[]);


  return (
   <div className='flex flex-column h-full container'>
    <div className='row hero items-center '>
            <div className='col-md-4 flex  items-center flex-column'>
                <h1 className='text-3xl text-orange-700 font-serif text-center'>Hello I am</h1>
                <div className='detail flex  items-center  flex-column'>
                <div data-aos='fade-right'className='flex justify-center flex-column  code-block  p-3 m-3'>
                    <p className='text-blue-700'>Name : &#123;</p>
                            <h6 className='ml-10 p-1 text-sm text-gray-300 font-light font-serif'>First  : ZaYn</h6>
                            <h6 className='ml-10 p-1 text-sm text-gray-300 font-serif'>Last  : Miraj</h6>
                        <p className='text-blue-700'>&#125;</p>
                </div>
                <div data-aos='fade-left' className=' flex justify-center flex-column code-block m-7  p-3 m-3'>
                    <p className='text-blue-700'>What I do  : &#123;</p> 
                            <h6 className='ml-10 p-1 text-sm text-gray-300 font-serif'>Proffesion : Software developer</h6>
                            <h6 className='ml-10 p-1 text-sm text-gray-300 font-serif'> Expereince : 4 years </h6>
                    <p className='text-blue-700'>&#125;</p>
                </div>
                </div>
                <div className='mt-2'>
                    <button className='btn code-block btn-sm text-gray-200 hover:bg-red-600 mr-2'>Download resume</button>
                    <button className='btn code-block-pro btn-sm text-orange-700  hover:bg-blue-600 '>Know me more</button>
                </div>
            </div>

            <div className='col-md-4 hero-img' data-aos="fade-up-left">
                <img alt="User_img" className=' ' src={info} />
            </div>
            <div className='col-md-4' data-aos="fade-zoom-right">
            <div data-aos='fade-right'className='flex justify-center flex-column  code-block  p-3 m-3'>
                    <p className='text-blue-700'>if (failed once) &#123;</p> 
                            <h6 className='ml-10 p-1 text-sm text-gray-300 font-light font-serif'>return "You've passed one step of succced"</h6>
                    <p className='text-blue-700'>&#125;</p>
                    <p className='text-blue-700'>elseif (disappointed) &#123;</p> 
                            <h6 className='ml-10 text-sm p-1 text-gray-300 font-light font-serif'>return "Never giv up You can"</h6>
                    <p className='text-blue-700'>&#125;</p>
                    <p className='text-blue-700'>else &#123;</p> 
                            <h6 className='ml-10 text-sm p-1 text-gray-300 font-light font-serif'>return "Succeed or lost"</h6>
                    <p className='text-blue-700'>&#125;</p>
                </div>
            </div>
    </div>



    <div className='w-full abouts p-10'>
        <div className="row flex  items-center justify-center">
            <div className='col-md-6 about' data-aos="fade-up-right">
                <img alt="User_img" className='w-3/4' src={info}  />
            </div>
            <div className='col-md-6' data-aos="zoom-in"> 
                <h1  className='text-left font-serif text-gray-300 text-2xl mt-4 mb-3 ' >Hi, <strong>It's Zayn Miraj</strong></h1>
                <p className='text-sm text-left font-serif text-gray-400'> Meet Miraj, a talented and dedicated mobile developer with a passion for creating innovative and user-friendly mobile applications. With a deep understanding of mobile technologies and a keen eye for detail,  I'm committed to delivering high-quality software solutions that exceed client expectations.
                    <br/>
                    <br/>
                    As a mobile developer, I'm possesses expertise in both Android and iOS platforms, allowing them to develop applications that cater to a wide range of users. They have a solid understanding of programming languages such as Java, Kotlin, Swift, and Objective-C, enabling them to write clean, efficient, and maintainable code.
                    <br/>
                    <br/>
                    As a mobile developer, I've  driven by a passion for creating impactful applications that make a difference in people's lives. They understand the importance of delivering software that is intuitive, efficient, and visually appealing. Whether it's a productivity app, social networking platform, e-commerce solution, or any other mobile application, I'm dedicated to delivering excellence, transforming ideas into reality, and making a positive impact in the mobile development landscape.
                    </p>
            </div>
        </div>
        <div className="row flex  items-center justify-between">
            <div data-aos="fade-right" className='col-md-6'> 
               <h2 className='text-center font-serif text-gray-300 text-2xl m-3'>Let's introduce</h2>
               <div className='m-3 info p-4 w-full'>
                    <h4 className='text-left w-full font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /><strong> Name : </strong>  ZaYn Miraj</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Live : </strong>  .....Hidden</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Age : </strong>  22 Years old</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Career : </strong>  Software developer</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Mobile : </strong>  +1000000000</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Eamil : </strong>  zaynmiraj@icloud.com</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Place of born : </strong>  Comilla , Bangladesh</h4>
               </div>
            </div>
            <div className='col-md-6 social' data-aos='zoom-in'> 
            <h2 className='text-center font-serif text-gray-300 text-2xl m-3'>Take to me on</h2>
            <div className='my-3 py-4 flex justify-between '>
                <div className=''>
                    <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-blue-700 bg-white text-3xl' icon={faFacebook} /> <span className='ml-2 text-lg'>@fedumiraj</span> </a>
                    <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-orange-700 bg-white text-3xl' icon={faInstagram} /> <span className='ml-2 text-lg'>@fedumiraj</span> </a>
                    <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-cyan-700 bg-white text-3xl' icon={faTwitter} /> <span className='ml-2 text-lg'>@zaynmiraj</span> </a>
                </div>
                    <div>
                        <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-red-700 bg-white text-3xl' icon={faGithub} /> <span className='ml-2 text-lg'>@zaynmiraj</span> </a>
                        <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-green-700 bg-white text-3xl' icon={faWhatsapp} /> <span className='ml-2 text-lg'>@zaynmiraj</span> </a>
                    </div>
            </div>
            </div>
        </div>
    </div>



    {/* Skills Section */}
    <div className='skills mt-10' id="skills">
        <h2 className='skills text-white code-block p-2  font-serif text-2xl text-center'> I Got Skills on </h2>
        <div className='row flex items-center justify-center'>
            <div data-aos='flip-right' className='col-md-6 items-center justify-center'>
                <h3 className='text-gray-300 p-3'> Frameworks</h3>
                <div className='process mt-8'>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-red-700   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Laravel</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-blue-700   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>React native</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-cyan-700   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>React js</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-green-700   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Next js</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-yellow-700   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Bootstrap</span> <span>95%</span>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-md-6 p-8' data-aos="flip-left">
                <h3 className='text-gray-300 p-3'> Languages</h3>
                <div className='process mt-8'>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-gray-700    flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Javascript</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-orange-700   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>MySqli</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-info  flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Node js</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger   flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Php</span> <span>95%</span>
                    </div>
                </div>
                <div className="progress h-4 m-4 ">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-secondary  flex flex-row justify-between items-center " role="progressbar" style={{width:'90%'}} aria-valuenow="90" aria-valuemin="90" aria-valuemax="100">
                        <span className='ml-2 '>Json</span> <span>95%</span>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>



    {/* Showcase Section */}
  


 {/* Team Section */}

    <div className=' team mt-10 mb-10 p-10 '>
        <div className=''>
            <div className=' text-center code-block p-2'><span className='font-serif text-2xl text-white'>Meet My Team</span></div>
            <div className='team-group row m-5' data-aos="zoom-in-up">
                <div className='col-md-3 code-block p-10   team-person flex flex-column items-center justify-center'>
                    <div className='person  z-10'>
                        <img alt="User_img" className='border' src='./team/girl-1.png' />
                    </div>
                    <div className='detail'>
                        <h3 className='font-serif text-lg text-center text-gray-300 m-1'>Linde gray</h3>
                        <h5 className='font-serif text-sm text-center text-gray-400 '>Software developer</h5>
                        <div className='flex justify-evanly mt-3'>
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-blue-700 text-3xl m-1' icon={faFacebook} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-orange-700 text-3xl m-1' icon={faInstagram} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-red-700 text-3xl m-1' icon={faGithub} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-cyan-700 text-3xl m-1'icon={faTwitter} />
                        </div>
                    </div>
                </div>
                <div className='col-md-3 code-block p-10 team-person flex flex-column items-center justify-center'>
                    <div className='person  z-10'>
                        <img alt="User_img" className='border' src='./team/man.png' />
                    </div>
                    <div className='detail'>
                        <h3 className='font-serif text-lg text-center text-gray-300 m-1'>Ajex Raie</h3>
                        <h5 className='font-serif text-sm text-center text-gray-400 '>Mern developer</h5>
                        <div className='flex justify-evanly mt-3'>
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-blue-700 text-3xl m-1' icon={faFacebook} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-orange-700 text-3xl m-1' icon={faInstagram} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-red-700 text-3xl m-1' icon={faGithub} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-cyan-700 text-3xl m-1'icon={faTwitter} />
                        </div>
                    </div>
                </div>
                <div className='col-md-3 code-block p-10 team-person flex flex-column items-center justify-center'>
                    <div className='person  z-10'>
                        <img alt="User_img" className='border' src='./team/smile-girl.png' />
                    </div>
                    <div className='detail'>
                        <h3 className='font-serif text-lg text-center text-gray-300 m-1'>Jay Eamily</h3>
                        <h5 className='font-serif text-sm text-center text-gray-400 '>Full-stack developer</h5>
                        <div className='flex justify-evanly mt-3'>
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-blue-700 text-3xl m-1' icon={faFacebook} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-orange-700 text-3xl m-1' icon={faInstagram} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-red-700 text-3xl m-1' icon={faGithub} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-cyan-700 text-3xl m-1'icon={faTwitter} />
                        </div>
                    </div>
                </div>
                <div className='col-md-3 code-block p-10 team-person flex flex-column items-center justify-center'>
                    <div className='person  z-10'>
                        <img alt="User_img" className='border' src='./team/girl-2.png' />
                    </div>
                    <div className='detail'>
                        <h3 className='font-serif text-lg text-center text-gray-300 m-1'>Ervine Quin</h3>
                        <h5 className='font-serif text-sm text-center text-gray-400 '>Data management</h5>
                        <div className='flex justify-evanly mt-3'>
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-blue-700 text-3xl m-1' icon={faFacebook} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-orange-700 text-3xl m-1' icon={faInstagram} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-red-700 text-3xl m-1' icon={faGithub} />
                        <FontAwesomeIcon className='bg-white icon p-2 rounded-full text-cyan-700 text-3xl m-1'icon={faTwitter} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    {/* Work Section */}
    <div className='work p-10 mt-10'>
        <h2 className='work text-2xl text-center font-serif'>I work with </h2>
        <div className='flex justify-evenly items-center m-10  p-10 '>
            <div className='w-50 p-3'>
                <img alt="User_img"  src='./photo/facebook.png' />
            </div>
            <div className='w-20 p-3'>
                <img alt="User_img" src='./photo/git.png' />
            </div>
            <div className='w-20 p-3'>
                <img alt="User_img" src='./photo/github.png' />
            </div>
            <div className='w-20 p-3'>
                <img alt="User_img" src='./photo/google.png' />
            </div>
            <div className='w-20 p-3'>
                <img alt="User_img" src='./photo/icon-stack.png' />
            </div>
            <div className='w-20 p-3'>
                <img alt="User_img" src='./photo/discord.png' />
            </div>
        </div>
    </div>

    <div className='contact p-10'>
            <div className=' flex flex-column justify-center items-center'>
                <h2 style={{padding:"5px 50px", width: "550px"}} className='contact  text-2xl px-10 text-center code-block text-white font-serif'>Drop me a text</h2>
                {notify ? <Alert severity="success">{notify}</Alert> : null}
                
                <form className='w-2/4' onSubmit={handleSubmit}>
                <label className="block mt-3">
                    <span className=" after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                        What's your name?
                    </span>
                    <input onChange={e => handleChanage(e)} type="text" name="name" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Your name" />
                </label>
                <label className="block mt-3">
                    <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                        Email
                    </span>
                    <input onChange={e => handleChanage(e)} type="email" name="email" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="you@example.com" />
                </label>
                <label className="block mt-3">
                    <span className="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                        Subject
                    </span>
                    <input onChange={e => handleChanage(e)} type="text" name="subject" className="mt-1 mt-2 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Subject" />
                </label>
                <label className="block mt-3">
                    <span class="after:content-['*'] after:ml-0.5 after:text-red-500 block text-sm font-medium text-slate-700">
                        What's on your mind?
                    </span>
                    <textarea onChange={e => handleChanage(e)} type="text" name="message" className="mt-1 px-3 py-2 bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1" placeholder="Type your message" />
                </label>
                <label className="block mt-4">
                    <button className='btn btn-sm w-full  code-block hover:bg-sky-600 text-white hover:text-white font-bold py-2 px-4 rounded-md'>Send message</button>
                </label>
                </form>
            </div>
        </div>
 
   </div>
  )
}

export default Home