import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Header() {

    const [nav , setNav ] = useState(false)

    const handleClick = () => {
        setNav(!nav)
    }

  return (
    <div className='flex  main-nav row justify-evenly items-center p-3 font-serif'>
    <div className='col-md-2 head'>
        <a className='text-white font-serif logo-brand  text-3xl' href='/'><img style={{borderRadius:"50%", padding:10}} src="./photo/logo.JPG" width="70px" height="70px" /></a>
        <div className='bars mr-4'> <FontAwesomeIcon onClick={handleClick} className='text-2xl text-white' icon={faBars} /></div>
    </div>
    <div className={!nav ? "none nav justify-center items-center col-md-6" : "blocks nav justify-center items-center col-md-6"}>
        <ul className=" navbar nav" >
            <li className="nav-item"><a href="" className="nav-link text-sm font-mono"><Link to="/" >Home</Link></a></li>
            <li className="nav-item"><Link className='nav-link text-sm font-mono' to="/service" >What I offer</Link></li>
            <li className="nav-item"><Link className='nav-link text-sm font-mono' to="/whatclientsay" >Reviews</Link></li>
            <li className="nav-item"><Link className='nav-link text-sm font-mono' to="/whoiam" >Who I am</Link></li>
            <li className="nav-item"><Link className='nav-link text-sm font-mono' to="/contact" >Contact</Link></li>
            <li className="nav-item"><Link className='nav-link text-sm font-mono' to="/blog" >Blog</Link></li>
        </ul>
    </div>
    <div className='col-md-2'>
        <a href='#' className='btn code-block getin btn-small text-orange-700 '>Get in touch</a>
    </div>
</div>
  )
}

export default Header