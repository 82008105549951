import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faGithub, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

function About() {
  return (
    <div className='w-full m-10 p-10 container'>
        <div className="row flex  items-center justify-center">
            <div className='col-md-6'>
                <img className='w-3/4' src='./photo/man.png'  />
            </div>
            <div className='col-md-6'> 
                <h1 className='text-center font-serif text-gray-300 text-3xl m-3 '>Hi, <strong>It's Zayn Miraj</strong></h1>
                <p className='text-sm text-left font-serif text-gray-400'> Meet Miraj, a talented and dedicated mobile developer with a passion for creating innovative and user-friendly mobile applications. With a deep understanding of mobile technologies and a keen eye for detail,  I'm committed to delivering high-quality software solutions that exceed client expectations.
                    <br/>
                    <br/>
                    As a mobile developer, I'm possesses expertise in both Android and iOS platforms, allowing them to develop applications that cater to a wide range of users. They have a solid understanding of programming languages such as Java, Kotlin, Swift, and Objective-C, enabling them to write clean, efficient, and maintainable code.
                    <br/>
                    <br/>
                    As a mobile developer, I've  driven by a passion for creating impactful applications that make a difference in people's lives. They understand the importance of delivering software that is intuitive, efficient, and visually appealing. Whether it's a productivity app, social networking platform, e-commerce solution, or any other mobile application, I'm dedicated to delivering excellence, transforming ideas into reality, and making a positive impact in the mobile development landscape.</p>
            </div>
        </div>
        <div className="row flex m-10   items-center justify-center">
            <div className='col-md-6'> 
               <h2 className='text-center font-serif text-gray-300 text-2xl m-3'>Let's introduce</h2>
               <div className='m-3 p-4'>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /><strong> Name : </strong>  ZaYn Miraj</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Live : </strong>  .....Hidden</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Age : </strong>  22 Years old</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Career : </strong>  Software developer</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Mobile : </strong>  +1000000000</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Eamil : </strong>  zaynmiraj@icloud.com</h4>
                    <h4 className='text-left font-serif text-gray-400 text-sm m-2'> <FontAwesomeIcon className='text-green-700 mr-1' icon={faArrowCircleRight} /> <strong>Place of born : </strong>  Comilla , Bangladesh</h4>
               </div>
            </div>
            <div className='col-md-6 '> 
            <h2 className='text-center font-serif text-gray-300 text-2xl m-3'>Take to me on</h2>
            <div className='m-3 p-4 flex justify-between '>
                <div className=''>
                    <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-blue-700 bg-white text-3xl' icon={faFacebook} /> <span className='ml-2 text-lg'>@fedumiraj</span> </a>
                    <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-orange-700 bg-white text-3xl' icon={faInstagram} /> <span className='ml-2 text-lg'>@fedumiraj</span> </a>
                    <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-cyan-700 bg-white text-3xl' icon={faTwitter} /> <span className='ml-2 text-lg'>@zaynmiraj</span> </a>
                </div>
                    <div>
                        <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-red-700 bg-white text-3xl' icon={faGithub} /> <span className='ml-2 text-lg'>@zaynmiraj</span> </a>
                        <a href='' className='text-left font-serif text-gray-400 flex items-center text-sm m-2'> <FontAwesomeIcon className='rounded-full p-2 text-green-700 bg-white text-3xl' icon={faWhatsapp} /> <span className='ml-2 text-lg'>@zaynmiraj</span> </a>
                    </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default About