import React from 'react'
import { BrowserRouter, Routes , Route } from 'react-router-dom'
import Home from './component/Home'
import Blog from './component/Blog'
import Header from './component/Header'
import Footer from './component/Footer'
import About from './component/About'
import Contact from './component/Contact'
import Service from './component/Service'


function App() {
  return (
    <div className='body container'>
      <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/whoiam' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/service' element={<Service />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </div>
  )
}

export default App